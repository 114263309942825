import {Injectable} from '@angular/core';
import {WindowService} from './window.service';

@Injectable({
    providedIn: 'root'
})
export class TokenService {
    constructor(private _windowService: WindowService) {
    }

    static get jwtToken(): string {
        return window['jwt'];
    }

    static set jwtToken(token: string) {
        window['jwt'] = token;
    }

    static get customerId(): string {
        return window['customerId'];
    }

    static set customerId(customerId: string) {
        window['customerId'] = customerId;
    }

    static set subId(subId: string) {
        window['subId'] = subId;
    }

    static get subId(): string {
        return window['subId'];
    }

    static get storeId(): string {
        return window['storeId'];
    }

    static set storeId(storeId: string) {
        window['storeId'] = storeId;
    }

    static get storeKardex(): string {
        return window['storeKardex'];
    }

    static set storeKardex(storeKardex: string) {
        window['storeKardex'] = storeKardex;
    }

    static set tab(status: boolean) {
        window['tab'] = status;
    }

    static get tab(): boolean {
        return window['tab'];
    }

    static set openTab(state: string) {
        window['openTab'] = state;
    }

    static get openTab(): string {
        return window['openTab'];
    }

    static set cartId(cartId: string) {
        window['cartId'] = cartId;
    }

    static get cartId(): string {
        return window['cartId'];
    }

    static set storeName(name: string) {
        window['storeName'] = name;
    }

    static get storeName(): string {
        return window['storeName'];
    }

    static set storeAddress(name: string) {
        window['storeAddress'] = name;
    }

    static get storeAddress(): string {
        return window['storeAddress'];
    }

    static set storeNumber(name: string) {
        window['storeNumber'] = name;
    }

    static get storeNumber(): string {
        return window['storeNumber'];
    }

    static set storeCvr(name: string) {
        window['storeCvr'] = name;
    }

    static get storeCvr(): string {
        return window['storeCvr'];
    }

    static set activeTab(tab: string) {
        window['activeTab'] = tab;
    }

    static get activeTab(): string {
        return window['activeTab'];
    }

    static set defaultZip(zip: string) {
        window['zipCode'] = zip;
    }

    static get defaultZip(): string {
        return window['zipCode'];
    }

    static get isSet(): boolean {
        return window['customerId'];
    }

    static get paddingTop(): number {
        return window['paddingTop'];
    }

    static set paddingTop(padding: number) {
        window['paddingTop'] = padding;
    }

    static get paddingBottom(): number {
        return window['paddingBottom'];
    }

    static set paddingBottom(padding: number) {
        window['paddingBottom'] = padding;
    }

    static get redirect(): boolean {
        return window['redirect'];
    }

    static set redirect(redirect: boolean) {
        window['redirect'] = redirect;
    }

    static get paymentResult(): boolean {
        return window['paymentResult'];
    }

    static set paymentResult(result: boolean) {
        window['paymentResult'] = result;
    }

    static set callbackUrl(state: boolean) {
        window['callbackUrlSupport'] = state;
    }

    static get callbackUrl(): boolean {
        return window['callbackUrlSupport'];
    }

    static set appVersion(version: string) {
        window['appVersion'] = version;
    }

    static get appVersion(): string {
        return window['appVersion'];
    }

    static set isFreemium(state: boolean) {
        // base 64 if text isFreemium is aXNGcmVlbWl1bQ== using this so that the freemium text is not visible
        window['aXNGcmVlbWl1bQ'] = state;
    }

    static get isFreemium() {
        return window['aXNGcmVlbWl1bQ'];
    }

    static set associatedStoreKardex(associatedStoreKardex: string) {
        window['associatedStoreKardex'] = associatedStoreKardex;
    }

    static get associatedStoreKardex(): string {
        return window['associatedStoreKardex'];
    }

    static set prefferedStoreKardex(prefferedStoreKardex: string) {
        window['preferredStoreKardex'] = prefferedStoreKardex;
    }

    static get prefferedStoreKardex(): string {
        return window['preferredStoreKardex'];
    }

}
